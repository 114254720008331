import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserRepository from "@/abstraction/repository/userRepository";

const repository = new UserRepository();

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    const users = ref([]);
    const pagination = ref([]);

    // Table Handlers
    const tableColumns = [
        { key: "user", sortable: true },
        { key: "verify-tick", sortable: true },
        { key: "username", sortable: true },
        { key: "created_at", sortable: true },
        { key: "email", sortable: true },
        { key: "role", sortable: true },
        { key: "actions" },
        // { key: 'edit-row' },
    ];
    const perPage = ref(100);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("Desc");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    const fetchUsers = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const usersList = await repository.index({ filters, pagination: paginate });
        console.log(usersList);
        users.value = usersList.data;
        pagination.value = usersList.pagination;
        return users;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = (role) => {
        if (role === "subscriber") return "primary";
        if (role === "author") return "warning";
        if (role === "maintainer") return "success";
        if (role === "editor") return "info";
        if (role === "admin") return "danger";
        return "primary";
    };

    const resolveUserRoleIcon = (role) => {
        if (role === "subscriber") return "UserIcon";
        if (role === "author") return "SettingsIcon";
        if (role === "maintainer") return "DatabaseIcon";
        if (role === "editor") return "Edit2Icon";
        if (role === "Manager") return "ServerIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
        if (status === "pending") return "warning";
        if (status === "active") return "success";
        if (status === "inactive") return "secondary";
        return "primary";
    };

    return {
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        users,
        pagination,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
